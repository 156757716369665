<template>
  <div>
    <div>
      <hb-header fewer-actions :padding="false" align-top class="mt-n2">
        <template v-slot:left>
          <hb-tabs v-model="activeTab" show-arrows>
            <v-tabs-slider color="#00848E"></v-tabs-slider>
            <v-tab v-for="menuOption in menuOptions" :key="menuOption.key" :href="'#tab-' + menuOption.key"
              @click="tabChanged(menuOption.key)">
              {{ menuOption.label }}
            </v-tab>
          </hb-tabs>
        </template>
        <template v-if="showAside" v-slot:right>
          <hb-btn icon tooltip="Sidebar" :class="{ 'mr-3': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xs }"
            @click="showHideSideBar(showIcon)" :active-by-default="true"
            :active-state-off="!showIcon">mdi-table-actions-custom-1</hb-btn>
        </template>
      </hb-header>
    </div>
    <div v-if="isPropertyLevel">
      <property-selector :property="property" @propertyChanged="propertyChanged"></property-selector>
    </div>
  </div>
</template>

<script type="text/babel">
import PropertySelector from './PropertySelector';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../../../src/EventBus.js';

export default {
  name: "MenuTabs",
  data() {
    return {
      activeTab: "corporate",
      property: null,
      showIcon: true
    };
  },
  props: {
    menuOptions: {
      type: Array,
      default: () => [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        }
      ]
    },
    showAside: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PropertySelector,
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isPropertyLevel() {
      return this.activeTab == 'tab-property' ? true : false;
    }
  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
    propertyChanged(property) {
      this.property = property;
      this.setDefaultProperty(property);
      this.$emit('selectedProperty', property);
    },
    tabChanged(option) {
      this.$emit('setMenuOption', option); 
      if(!this.isPropertyLevel) {
        this.propertyChanged(this.defaultProperty ? this.defaultProperty : null)
      }
    },
    showHideSideBar(flag){
       this.showIcon = !this.showIcon;
       EventBus.$emit('showHideSidebar', this.showIcon);
    }
  }
}
</script>

<style lang="scss" scoped></style>